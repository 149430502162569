#homepage-7 {
    .ps-home-banner {
        padding-top: 30px;
        padding-bottom: 40px;

        .owl-slider {
            .owl-item {
                img {
                    width: 100%;
                }
            }
        }

        .container {
            display: flex;
            flex-flow: row nowrap;

            > * {
                width: 100%;
            }

            .ps-section__left {
                max-width: calc(100% - 380px);
                padding-right: 30px;
            }

            .ps-section__right {
                max-width: 380px;

                .ps-collection {
                    margin-bottom: 30px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            @include media("<lg") {

                flex-flow: column wrap;
                
                .ps-section__left {
                    max-width: 100%;
                    margin-bottom: 30px;
                    padding-right: 0;
                }

                .ps-section__right {
                    width: 100%;
                    max-width: 100%;
                    display: flex;
                    flex-flow: row nowrap;

                    .ps-collection {
                        width: 100%;
                        max-width: 50%;
                        margin: 0 10px;
                    }
                }
            }
        }

    }

    .ps-top-categories {
        h3 {
            font-size: 24px;
        }
    }

    .ps-promotions {
        padding: 40px 0;

        .ps-collection {
            @include media("<sm") {
                margin-bottom: 30px;
            }
        }
    }

    .ps-product-list {
        margin-bottom: 50px;
    }

    .ps-deal-of-day {
        .ps-product {
            .ps-product__price {
                color: #690;
                font-size: 18px;

                del {
                    margin-left: 10px;
                    font-size: 14px;
                }

                small {
                    margin-left: 10px;
                    color: red;
                }
            }
        }
    }

    .ps-home-blog {
        padding-top: 50px;
        padding-bottom: 70px;
        background-color: #f5f5f5;

        .ps-block--recent-viewed {
            padding-top: 70px;
            border-top: 1px solid #e3e3e3;
        }
    }
}