.ps-btn, button.ps-btn {
  background-color: #dd2400;
  color: #ffffff;
}

.ps-btn:hover, button.ps-btn:hover {
  background-color: #c42000;
}

.header--electronic {
  background-color: #022335;
}

.header--electronic .header__extra i {
  color: #ffffff;
}

.header--electronic .header__extra span {
  background-color: #dd2400;
}

.header--electronic .header__extra span i {
  color: #ffffff;
}

.header--electronic .header__extra:hover > i {
  color: #dd2400;
}

.header--electronic .header__extra:hover span {
  color: #dd2400;
}

.header--electronic .ps-block--user-header i {
  color: #ffffff;
}

.header--electronic .ps-block--user-header a {
  color: #ffffff;
}

.header--electronic .ps-block--user-header a:hover {
  color: #dd2400;
}

.header--electronic .ps-form--quick-search input {
  border-right: none;
}

.header--electronic .ps-form--quick-search button {
  background-color: #dd2400;
  color: #ffffff;
}

.header--electronic .header__top {
  background-color: #022335;
}

.header--electronic .header__top .header__left p {
  color: #ffffff;
}

.header--electronic .header__top .header__top-links > li > a {
  color: #ffffff;
}

.header--electronic .header__top .header__top-links .ps-dropdown > a {
  color: #ffffff;
}

.header--electronic .header__content {
  background-color: #022335;
}

.header--electronic .header__content .menu--product-categories {
  display: block;
}

.header--electronic .header__content .menu--product-categories .menu__toggle i {
  color: #ffffff;
}

.header--electronic .header__content .menu--product-categories .menu__toggle span {
  display: none;
}

.header--electronic .navigation {
  background-color: #022335;
}

.header--electronic .menu--electronic > li > a {
  color: #ffffff;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
}

.header--electronic .menu--electronic > li > a i {
  vertical-align: middle;
  margin-right: 6px;
  font-size: 20px;
}

.header--electronic .menu--electronic > li > a:hover {
  color: #dd2400;
}

.header--electronic.header--sticky .header__content {
  background-color: #022335;
}

.header--electronic.header--sticky .header__content .menu--product-categories .menu__toggle span {
  display: block;
  color: #ffffff;
}

.header--mobile.electronic {
  background-color: #fff;
}

.header--mobile.electronic .ps-cart--mini {
  text-align: left;
}

.header--mobile.electronic .header__actions .ps-cart--mini > a {
  color: #ffffff;
}

.header--mobile.electronic .header__actions .ps-cart--mini > a span {
  background-color: #dd2400;
}

.header--mobile.electronic .header__actions .ps-block--user-header .ps-block__left i {
  color: #ffffff;
}

.header--mobile.electronic .navigation--mobile {
  background-color: #022335;
}

.header--mobile.electronic .ps-search--mobile {
  background-color: #dd2400;
}

.navigation--list .navigation__item.active i, .navigation--list .navigation__item.active span, .navigation--list .navigation__item:focus i, .navigation--list .navigation__item:focus span, .navigation--list .navigation__item:active i, .navigation--list .navigation__item:active span, .navigation--list .navigation__item:hover i, .navigation--list .navigation__item:hover span {
  color: #dd2400;
}

.ps-panel--sidebar .ps-panel__header {
  background-color: #dd2400;
}

.ps-banner--furniture {
  position: relative;
}

.ps-banner--furniture h4 {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  color: #0098ce;
}

.ps-banner--furniture h3 {
  font-size: 36px;
  font-weight: 300;
  line-height: 1.2em;
}

.ps-banner--furniture h3 strong {
  font-weight: 600;
  color: #669900;
}

.ps-banner--furniture .ps-btn {
  margin-top: 20px;
  padding: 12px 24px;
}

.ps-banner--furniture .ps-banner__content {
  max-width: 1200px;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding-left: 140px;
}

@media (max-width: 1680px) {
  .ps-banner--furniture h4 {
    margin-bottom: 10px;
  }
  .ps-banner--furniture h3 {
    font-size: 40px;
    line-height: 1.2em;
  }
}

@media (max-width: 1366px) {
  .ps-banner--furniture h4 {
    margin-bottom: 10px;
    font-size: 16px;
  }
  .ps-banner--furniture h3 {
    font-size: 40px;
  }
  .ps-banner--furniture h3 br {
    display: none;
  }
}

@media (max-width: 1199px) {
  .ps-banner--furniture {
    min-height: 400px;
  }
  .ps-banner--furniture img {
    display: none;
    visibility: hidden;
    opacity: 0;
  }
  .ps-banner--furniture .ps-banner__content {
    padding: 0 30px;
  }
}

@media (max-width: 479px) {
  .ps-banner--furniture h3 {
    font-size: 24px;
  }
  .ps-banner--furniture p {
    font-size: 12px;
  }
}

#homepage-7 .ps-home-banner {
  padding-top: 30px;
  padding-bottom: 40px;
}

#homepage-7 .ps-home-banner .owl-slider .owl-item img {
  width: 100%;
}

#homepage-7 .ps-home-banner .container {
  display: flex;
  flex-flow: row nowrap;
}

#homepage-7 .ps-home-banner .container > * {
  width: 100%;
}

#homepage-7 .ps-home-banner .container .ps-section__left {
  max-width: calc(100% - 380px);
  padding-right: 30px;
}

#homepage-7 .ps-home-banner .container .ps-section__right {
  max-width: 380px;
}

#homepage-7 .ps-home-banner .container .ps-section__right .ps-collection {
  margin-bottom: 30px;
}

#homepage-7 .ps-home-banner .container .ps-section__right .ps-collection:last-child {
  margin-bottom: 0;
}

@media (max-width: 1199px) {
  #homepage-7 .ps-home-banner .container {
    flex-flow: column wrap;
  }
  #homepage-7 .ps-home-banner .container .ps-section__left {
    max-width: 100%;
    margin-bottom: 30px;
    padding-right: 0;
  }
  #homepage-7 .ps-home-banner .container .ps-section__right {
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-flow: row nowrap;
  }
  #homepage-7 .ps-home-banner .container .ps-section__right .ps-collection {
    width: 100%;
    max-width: 50%;
    margin: 0 10px;
  }
}

#homepage-7 .ps-top-categories h3 {
  font-size: 24px;
}

#homepage-7 .ps-promotions {
  padding: 40px 0;
}

@media (max-width: 767px) {
  #homepage-7 .ps-promotions .ps-collection {
    margin-bottom: 30px;
  }
}

#homepage-7 .ps-product-list {
  margin-bottom: 50px;
}

#homepage-7 .ps-deal-of-day .ps-product .ps-product__price {
  color: #690;
  font-size: 18px;
}

#homepage-7 .ps-deal-of-day .ps-product .ps-product__price del {
  margin-left: 10px;
  font-size: 14px;
}

#homepage-7 .ps-deal-of-day .ps-product .ps-product__price small {
  margin-left: 10px;
  color: red;
}

#homepage-7 .ps-home-blog {
  padding-top: 50px;
  padding-bottom: 70px;
  background-color: #f5f5f5;
}

#homepage-7 .ps-home-blog .ps-block--recent-viewed {
  padding-top: 70px;
  border-top: 1px solid #e3e3e3;
}
